<template>
  <ion-page>
    <HeaderOne v-bind:name="$t('profile')" />
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">
            {{$t('profile')}}
          </ion-title>
        </ion-toolbar>
      </ion-header>

      <h1 v-if="me" class="me-title">Hi {{me.first_name}}!</h1>

      <ion-list>
        <template
          v-for="link in links"
          :key="link.id"
        >
          <ion-item button :href="link.href">
            <ion-icon
              slot="start"
              :icon="link.icon"
              color="primary"
            />
            <ion-label>{{ $t(link.name) }}</ion-label>
          </ion-item>
        </template>

        <!-- Vue Router -->
        <ion-item router-link="/tabs/wetter">
          <ion-icon
                  slot="start"
                  :icon="sunnyOutline"
                  color="primary"
          />
          <ion-label>{{ $t('weather.name') }}</ion-label>
        </ion-item>

        <ion-item router-link="/tabs/stundenplan">
          <ion-icon
            slot="start"
            :icon="calendarOutline"
            color="primary"
          />
            <ion-label>{{ $t('timetable') }}</ion-label>
          </ion-item>

        <ion-item router-link="/tutorial">
          <ion-icon
                  slot="start"
                  :icon="helpCircleOutline"
                  color="primary"
          />
          <ion-label>{{ $t('auth.tutorial') }}</ion-label>
        </ion-item>

          <!-- Language Switcher -->
          <ion-item>
            <ion-icon
              slot="start"
              :icon="languageOutline"
              color="primary"
            />
            <ion-label>{{$t('language')}}</ion-label>
            <ion-select v-model="$i18n.locale">
              <ion-select-option v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`" :value="locale">{{ locale.toUpperCase() }}</ion-select-option>
            </ion-select>
          </ion-item>

          <ion-item button @click="logout()">
            <ion-icon
                    slot="start"
                    :icon="logOutOutline"
                    color="primary"
            />
            <ion-label>{{ $t('auth.logout') }}</ion-label>
          </ion-item>

          <ion-item router-link="/tabs/credits">
            <ion-icon
                    slot="start"
                    :icon="informationOutline"
                    color="primary"
            />
            <ion-label>{{ $t('credits') }}</ion-label>
          </ion-item>

      </ion-list>

    </ion-content>
  </ion-page>
</template>

<script lang="ts">
    import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonItem, IonList, IonIcon, IonLabel, IonSelect, IonSelectOption } from '@ionic/vue';
    import HeaderOne from '@/components/HeaderOne.vue';
    import { calendarOutline, bookOutline, mailUnreadOutline, globeOutline, languageOutline, logOutOutline, helpCircleOutline, informationOutline, sunnyOutline } from "ionicons/icons";
    import {app, directus} from '@/main';

    export default  {
        name: 'Profil',
        components: { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, HeaderOne, IonItem, IonList, IonIcon, IonLabel, IonSelect, IonSelectOption },
        data: () => {
            return {
                links: [
                    {
                        id: 1,
                        icon: bookOutline,
                        name: 'moodle',
                        href: 'https://moodle.th-brandenburg.de/'
                    },
                    {
                        id: 2,
                        icon: mailUnreadOutline,
                        name: 'mail',
                        href: 'https://www.zimbra.th-brandenburg.de'
                    },
                    {
                        id: 3,
                        icon: globeOutline,
                        name: 'website',
                        href: 'https://www.th-brandenburg.de/startseite/'
                    },
                ],
                me: null,
            }
        },
      methods: {
          logout () {
            directus.auth.logout()
            app.config.globalProperties.loggedIn = false;
            this.$router.push({name:'logout'})
          }
      },
      created () {
        directus.users.me.read()
          .then(res => {
            this.me = res.data;
          });
      },
      setup() {
        return {
          calendarOutline,
          bookOutline,
          mailUnreadOutline,
          globeOutline,
          languageOutline,
          logOutOutline,
          helpCircleOutline,
          informationOutline,
          sunnyOutline
        }
      }
    }
</script>

<style scoped>
  .me-title {
    margin: 50px 20px 30px;
    font-weight: 800;
  }
</style>
