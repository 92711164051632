
    import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonItem, IonList, IonIcon, IonLabel, IonSelect, IonSelectOption } from '@ionic/vue';
    import HeaderOne from '@/components/HeaderOne.vue';
    import { calendarOutline, bookOutline, mailUnreadOutline, globeOutline, languageOutline, logOutOutline, helpCircleOutline, informationOutline, sunnyOutline } from "ionicons/icons";
    import {app, directus} from '@/main';

    export default  {
        name: 'Profil',
        components: { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, HeaderOne, IonItem, IonList, IonIcon, IonLabel, IonSelect, IonSelectOption },
        data: () => {
            return {
                links: [
                    {
                        id: 1,
                        icon: bookOutline,
                        name: 'moodle',
                        href: 'https://moodle.th-brandenburg.de/'
                    },
                    {
                        id: 2,
                        icon: mailUnreadOutline,
                        name: 'mail',
                        href: 'https://www.zimbra.th-brandenburg.de'
                    },
                    {
                        id: 3,
                        icon: globeOutline,
                        name: 'website',
                        href: 'https://www.th-brandenburg.de/startseite/'
                    },
                ],
                me: null,
            }
        },
      methods: {
          logout () {
            directus.auth.logout()
            app.config.globalProperties.loggedIn = false;
            this.$router.push({name:'logout'})
          }
      },
      created () {
        directus.users.me.read()
          .then(res => {
            this.me = res.data;
          });
      },
      setup() {
        return {
          calendarOutline,
          bookOutline,
          mailUnreadOutline,
          globeOutline,
          languageOutline,
          logOutOutline,
          helpCircleOutline,
          informationOutline,
          sunnyOutline
        }
      }
    }
